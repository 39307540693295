import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  asseetId: "-",
  modelId: "-",
  isBroken: false,
  normalPicUrl:
    "https://cdn.discordapp.com/attachments/1049302094810718219/1110947970863611914/cctv_ieat.png",
  brokenPicUrl:
    "https://cdn.discordapp.com/attachments/1049302094810718219/1110949230048182383/cctv_ieat_broken.png",
  isOpenEntranceExitPopUp: false,
  isExtrance: false,
  isExit: false,
  entrancePicUrl:
    "https://cdn.discordapp.com/attachments/1118003174528262295/1120318744665792532/cctv_car1.png",
  exitPicUrl:
    "https://cdn.discordapp.com/attachments/1118003174528262295/1120318745055858838/cctv_car2.png",

  selected_DeviceId: undefined,
  select_live_camera: undefined,
};
const CCTVlSlice = createSlice({
  name: "cctvEnvs",
  initialState: initialState,

  reducers: {
    setEnv(state: any, action) {
      state.asseetId = action.payload.asseetId;
      state.modelId = action.payload.modelId;
      state.isBroken = action.payload.isBroken;
    },
    readValue(state: any) {
      console.log({
        asseetId: state.asseetId,
        modelId: state.modelId,
        isBroken: false,
        normalPicUrl:
          "https://cdn.discordapp.com/attachments/1049302094810718219/1110947970863611914/cctv_ieat.png",
        brokenPicUrl:
          "https://cdn.discordapp.com/attachments/1049302094810718219/1110949230048182383/cctv_ieat_broken.png",
      });
    },
    getPic(state: any) {
      if (state.isBroken) {
        return state.brokenPicUrl;
      }
      return state.normalPicUrl;
    },
    openEntranceExitPopUp(state: any, action) {
      state.isOpenEntranceExitPopUp = true;
      if (action.payload["title"] === "entrance") {
        state.isExtrance = true;
        state.isExit = false;
      } else if (action.payload["title"] === "exit") {
        state.isExit = true;
        state.isExtrance = false;
      }
    },
    closeEntranceExitPopUp(state: any) {
      state.isOpenEntranceExitPopUp = false;
    },
    closePicturePopUp(state: any) {
      state.asseetId = "-";
    },

    setSelected_DeviceId(state: any, action) {
      state.selected_DeviceId = action.payload;
    },
    setSelectd_LiveCamera(state: any, action) {
      try {
        state.select_live_camera = action.payload.profile.cctv_link;
      } catch (error) {
        state.select_live_camera = undefined;
      }
    },
  },
});

export const CCTVlSliceActions = CCTVlSlice.actions;
export default CCTVlSlice.reducer;
