import { configureStore } from '@reduxjs/toolkit'
import menu_controll_slice from './menu_controll_slice'
import accidentPlanSlice from './accident-plan-slice'
import flood_controlSlice from './flood_control-slice'
import cctvSlice from './cctv-slice'
import object_select_slice from './object_select_slice'
import element_of_interest from './element_of_interest'
import viewerSlice from './viewer-slice'
import factorySlice from './rightdashboard/factory/factory-slice'
import GoodWaterFromCenterSlice from './rightdashboard/good_water_from_center/GoodWaterFromCenterSlice'
import asset_table_slice from './rightdashboard/asset_table/asset_table_slice'
import weather_slice from './rightdashboard/weather/weather_slice'
import airQualitySlice from './rightdashboard/air_quality/airQualitySlice'
import bad_water_from_center_slice from './rightdashboard/bad_water_from_center/bad_water_from_center_slice'
import flood_control_v2Slice from './flood_control_v2-slice'
// ...

const rightDashBoardReducer={
  weatherEnv:weather_slice,
  airQualityEnv:airQualitySlice,

  assetTableEnv:asset_table_slice,
  badWaterFromCenterEnv:bad_water_from_center_slice,
  goodWaterFromCenterEnv:GoodWaterFromCenterSlice
}
export const mainStore = configureStore({
  reducer: {
    menuControll: menu_controll_slice,
    // rightDashBoardEnv:right_dashboard_slice,
    accidentPlanEnv:accidentPlanSlice,
    FloodControlEnvs:flood_controlSlice,
    FloodControlv2Envs:flood_control_v2Slice,
    cctvEnvs:cctvSlice,
    objectSelectEnv:object_select_slice,
    elementOfInterestEnv:element_of_interest,
    factorynEnv:factorySlice,
    viewerEnv: viewerSlice,
    ...rightDashBoardReducer,
  },
 
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof mainStore.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof mainStore.dispatch