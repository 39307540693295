import { PROTOCOLType, DOMAINNAME } from "enum";

export const PROTOCOL = PROTOCOLType.HTTPS;
export const DOMAIN_NAME = DOMAINNAME.AMS;
// export const PROTOCOL = PROTOCOLType.HTTP
// export const DOMAIN_NAME = DOMAINNAME.TEST

export enum ItwinProject {
  IEAT = "IEAT",
  REIGNWOOD = "REIGNWOOD",
}

export const getTokenItwin = (project: ItwinProject) => {
  switch (project) {
    case ItwinProject.IEAT:
      return "99e6e28a1f694cab847dd7342b6984aa";
    case ItwinProject.REIGNWOOD:
      return "a4c2524c-8e1c-4d4e-b2ce-302ec5514c01";
    default:
      return "";
  }
};
