import { FC, Suspense, lazy } from "react";
import { Route, Routes as Switch } from "react-router-dom";

import { ItwinRoutePath } from "./Route_Path";

const NotFound = lazy(() => import("./notFound/NotFound"));
const ViewerPage = lazy(() => import("./ViewerPage"));
const Login_IEAT = lazy(() => import("./login/Login_IEAT"));
const AdminViewer = lazy(() => import("page/admin/Admin_Viewer"));
const ViewerFactoryPage = lazy(() => import("./factory/ViewerFactoryPage"));
const ViewerHomeMenu = lazy(() => import("./home/Viewer_home"));

const Routes: FC = () => {
  return (
    <Switch>
      <Route
        path={ItwinRoutePath.HOME_PATH}
        element={
          <Suspense fallback={<></>}>
            <ViewerHomeMenu />
          </Suspense>
        }
      />
      <Route
        path={ItwinRoutePath.ADMIN_PATH}
        element={
          <Suspense fallback={<></>}>
            <AdminViewer />
          </Suspense>
        }
      />

      {/* Viewer */}
      <Route
        path={ItwinRoutePath.VIEWER_PATH}
        element={
          <Suspense fallback={<></>}>
            <ViewerPage version={"v1"} />
          </Suspense>
        }
      />
      <Route
        path={ItwinRoutePath.VIEWER_UI_2_PATH}
        element={
          <Suspense fallback={<></>}>
            <ViewerPage version={"v2"} />
          </Suspense>
        }
      />
      <Route
        path={ItwinRoutePath.VIEWER_FACTORY_PATH}
        element={
          <Suspense fallback={<></>}>
            <ViewerFactoryPage />
          </Suspense>
        }
      />

      <Route
        path={ItwinRoutePath.VIEWER_DIGITAL_ITWIN}
        element={
          <Suspense fallback={<></>}>
            <ViewerPage version={"v1"} />
          </Suspense>
        }
      />

      <Route
        path={ItwinRoutePath.LOGIN_PATH}
        element={
          <Suspense fallback={<></>}>
            <Login_IEAT />
          </Suspense>
        }
      />

      <Route path="*" element={<NotFound />} />
    </Switch>
  );
};

export default Routes;
