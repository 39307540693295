import { createSlice } from "@reduxjs/toolkit";

import { SelectedElement } from 'interface'

interface ViewerSliceTypes {
  selectedElement: SelectedElement,
  projectToken: string | undefined,
}

const initialState: ViewerSliceTypes = {
  selectedElement: {elementId: '', className: ''},
  projectToken: undefined,
};

const viewerSlice = createSlice({
  name: "viewerEnv",
  initialState: initialState,
  reducers: {

    setSelectedElement(state: any, action) {      
      if (action.payload.elementId == state.selectedElement.elementId) {
        state.selectedElement = {elementId: '', className: ''}
      }else {
        state.selectedElement = action.payload
      }
    },

    clearSelectedElement(state: any) {
      state.selectedElement = {elementId: '', className: ''}
    },

    testSelected(state:any, action) {
      console.log(action);
      
    },

    setProjectToken(state: any, action) {
      state.projectToken = action.payload
    }
  },
});

export const viewerActions = viewerSlice.actions;
export default viewerSlice.reducer;
