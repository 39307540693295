import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import axios from "axios";
import { RequestItwinProp } from "interface/request_props";
import { URLLeftMenu } from "url/left_menu/url";

export const fetchAirQuality = createAsyncThunk("airQualityApi", async () => {
  const res = await axios(URLLeftMenu.URL_AIR_QUALITY());
  const data = await res.data;

  return data;
});
export type airQualityProps = {
  device_id: string;
  timestamp: string;
  battery: string;
  temp: number;
  co: number;
  no2: number;
  so2: number;
  o3: number;
  pm25: number;
  pm10: number;
};
export type dataAirQualitySliceProps = {
  data: airQualityProps;
  airQualityElementId: string;
  isHaveData: boolean;
} & RequestItwinProp;
const initialState = {
  data: {},
  isLoading: true,
  isHaveData: false,
  error: "",
  airQualityElementId: "0x20000001e3c",
};
const AirQualitySlice = createSlice({
  name: "airQualityEnv",
  initialState: initialState,
  extraReducers: (builder) => {
    builder
      .addCase(fetchAirQuality.pending, (state) => {
        if (!state.isHaveData) {
          state.isLoading = true;
        }
      })
      .addCase(fetchAirQuality.fulfilled, (state, action) => {
        state.data = action.payload;
        state.isHaveData = true;
        state.isLoading = false;
      })
      .addCase(fetchAirQuality.rejected, (state, action) => {
        state.error = action.error.message!;
        state.isLoading = false;
      });
  },
  reducers: {
    clearData(state: any) {
      state.data = {};
      state.isLoading = false;
      state.error = "";
      state.isHaveData = false;
    },
  },
});

export const getAllDataAirQualitySlice = (state: any) => state.airQualityEnv;
export const AirQualityActions = AirQualitySlice.actions;
export default AirQualitySlice.reducer;
