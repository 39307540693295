import { createSlice } from "@reduxjs/toolkit";

const objectSelectSlice = createSlice({
  name: "objectSelectEnv",
  initialState: {
    id:"my Id",
    
  },
  reducers: {
    hello(state:any){
        console.log('hello');
        
    },
    setIdElement(state: any, action) { 
        
      state.id = action.payload['id']    
    },
  },
});

export const objectSelectActions = objectSelectSlice.actions;
export default objectSelectSlice.reducer;
