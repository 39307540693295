
import { ItwinRoutePath } from "page/Route_Path";
import { ItwinProject, getTokenItwin } from "utility/env";

type IGetTokenProps = {
  parameter?: string ;
};
export default function getTokenFromUrlParameter({ parameter = "project_token" }: IGetTokenProps): string | null {

  const pathName=  window.location.pathname
  if (pathName === ItwinRoutePath.VIEWER_DIGITAL_ITWIN) {
    return getTokenItwin(ItwinProject.IEAT)
  }
  const urlParams = new URLSearchParams(window.location.search);

  const token: string | null = urlParams.get(parameter);
  return token;
}
