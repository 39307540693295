import { IEAT_MachineStatusDialogTag } from "enum";
import getTokenFromUrlParameter from "hook/useGetTokenHoook";
import { BASEURL, URLGeneral } from "url/url_main";

export class URLLeftMenu {
  //รายการทรัพสิน
  static URL_ASSET_TABLE = () =>
    `${BASEURL}/api/add_assetlist-water-treatment-Api/`;
  //cctv
  static URL_CCTV_Image_Storage = () => `${BASEURL}/IEAT/api/v1/cctv/image/`;
  //weather สภาพภูมิอากาศ
  static URL_WEATHER = () =>
    URLGeneral.URL_MACHINE_STATUS(IEAT_MachineStatusDialogTag.WEATHER);

  //air quality คณภาพอากาศ
  static URL_AIR_QUALITY = () =>
    URLGeneral.URL_MACHINE_STATUS(IEAT_MachineStatusDialogTag.AIR_QUALITY);
  // system control ระบบควบคมน้ำท่่วม
  static URL_SYSTEM_CONTROL = () =>
    URLGeneral.URL_MACHINE_STATUS(IEAT_MachineStatusDialogTag.FLOOD_CONTROL);
  //factory
  static URL_FACTORY_DATA = () => {
    const token = getTokenFromUrlParameter({});

    return `${BASEURL}/IEAT/api/v1/factory/data/?project_token=${token}`;
  };

  //GoodWater น้ำประปา
  static URL_GOOD_WATER_METER = () =>
    `${BASEURL}/IEAT/api/v1/good-water-meter/`;
  //as-build
  static URL_AS_BUILT = () => `${BASEURL}/media/as_built/asbuilt-drawing.zip`;
}
