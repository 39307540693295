export enum ActionType {
  WALL = 'Wall',
  FLOOR = 'Floor',
  FULLSCREEN = 'FullScreen',
}

export enum DialogType {
  RENT_CHART = 'ChartDialog_1',
  TEMP_CHART = 'ChartDialog_2',
  PROPERTY = 'Property',
  OVERLAY = 'Overlay',
}

export enum ModalType {
  ADVERTISING = 'Advertising',
  AHU = 'AHU',
  MACHINE = 'Machine',
  LEASING = 'Leasing',
  ALL = 'ALL',
  LIGHTING = 'Lighting',
  ESCALATOR = 'Escalator',
  PARKING = 'Parking',
  CIRCULATION = 'Circulation',
}

export enum ModeType {
  ADVERTISING = 'Advertising',
  AHU = 'AHU',
  MACHINE = 'Machine',
  LEASING = 'Leasing',
  ALL = 'ALL',
  LIGHTING = 'Lighting',
  ESCALATOR = 'Escalator',
  PARKING = 'Parking',
  CIRCULATION = 'Circulation',
  IEATDASHBOARD = 'IEATDashboard',
}

export enum StateType {
  VIEWER = 'viewer',
  SELECTED_PID = 'selected_pid',
  MODE = 'mode',
  CANOPENDIALOG_PROPERTY = 'canOpenDialog_Property',
  CANOPENDIALOG_OVERLAYVIEW = 'canOpenDialog_Overlayview',
  PROJECT_TOKEN = 'project_token',
  REFRESH = 'refresh',

}

export enum PROTOCOLType {
  HTTP = 'http',
  HTTPS = 'https'
}

export enum DOMAINNAME {
  TEST = 'localhost:8000',
  AMS = 'bim-ams.com',
  IOTFarmdasia= 'iot.farmdasia'
}

export enum RightIEATDashboardTypeEng {
  OVERVIEW = 'Overview',
  ASSET_VIEW = 'Asset View',
  CCTV = 'CCTV',
  LIGHTING = 'Lighting',
  WEATHER = 'Weather',
  AIR_QUALITY = 'Air Quality',
  FACTORY = 'Factory',
  FLOOD_CONTROL = 'Flood Control',
  WASTE_MGMT = 'Waste MGMT.',
  RECORD_MAINTAIN = 'Record  Maintain',
  
}


export enum RightIEATDashboardTypeTh {
  OVERVIEW = "ภาพรวม",
  ASSET_VIEW ="รายการทรัพย์สิน",
  CCTV ="กล้องวงจรปิด",
  // CCTVLICENCE ="กล้องตรวจจับทะเบียนรถ",
  LIGHTING = 'แสงสว่างส่วนกลาง',
  WEATHER = 'สภาพภูมิอากาศ',
  AIR_QUALITY = 'คุณภาพอากาศ',
  SYSTEM_CONTROL_FLOOD = 'ระบบควบคุมน้ำท่วม',
  FACTORY = 'โรงงาน',
  FLOOD_CONTROL = 'น้ำเสียส่วนกลาง',
  GOODWATER_FROM_CENTER = 'น้ำประปาส่วนกลาง',
  RECORD_MAINTAIN = "บันทึกการบำรุงรักษา",
  AS_BUILT = " ข้อมูลสาธารณูปโภค",
  ACCIDENT_PLAN = "ข้อมูลอุบัติเหตุ",
  
}

export enum IEAT_MachineStatusDialogTag {
  OVERVIEW = 'overview',
  WASTE_WATER = 'wasteWater',
  AIR_QUALITY = 'airQuality',
  FLOOD_CONTROL = 'floodControl',
  WEATHER = 'weather',
  LIGHTING = 'lighting',
  FLOW_AND_DO = 'flowAndDo'
}




export enum ChartWaterCode {
  GOOD_1 = 'Good_1',
  GOOD_2 = 'Good_2',
  WASTE_1 = 'Waste_1',
  WASTE_2 = 'Waste_2'
}

