export class ItwinRoutePath {
  static HOME_PATH = "/";
  static ADMIN_PATH = "/admin";
  static HOME_MENU_PATH = "/menu";
  static LOGIN_PATH = "/login";
  static VIEWER_PATH = "/viewer";
  static VIEWER_UI_2_PATH = "/viewer-ui2";
  static VIEWER_FACTORY_PATH = "/viewer-factory";
  static VIEWER_DIGITAL_ITWIN = "/digitaltwin";
}
