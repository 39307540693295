import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import axios from "axios";
import { RequestItwinProp } from "interface/request_props";
import { URLLeftMenu } from "url/left_menu/url";

export const fetchGoodWaterFromCenter = createAsyncThunk(
  "goodwaterformcenterapi",
  async () => {
    const res = await axios(URLLeftMenu.URL_GOOD_WATER_METER());
    const data = await res.data.reverse();

    return data;
  }
);
export type GoodWaterFromCenterSliceProps = {
  pump_title: string;
  value: number;
  timestamp: string;
  last_different: number;
};
export type GoodWaterFromCenterSlicePropsSlce = {
  data: GoodWaterFromCenterSliceProps[];
  phElementIdLists: string[];
} & RequestItwinProp;
const initialState: GoodWaterFromCenterSlicePropsSlce = {
  data: [],
  isLoading: true,
  error: null,
  phElementIdLists: [
    "0x1100000003f7",
    "0x20000000b42",
    "0x20000001784",
    "0x20000000697",
    "0x200000018f5",
    "0x20000001947",
    "0x2000000122c",
    "0x2000000255d",
  ],
};
const GoodWaterFromCenterSlice = createSlice({
  name: "goodWaterFromCenterEnv",
  initialState: initialState,
  extraReducers: (builder) => {
    builder
      .addCase(fetchGoodWaterFromCenter.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchGoodWaterFromCenter.fulfilled, (state, action) => {
        state.data = action.payload;
        state.error = null;
        state.isLoading = false;
      })
      .addCase(fetchGoodWaterFromCenter.rejected, (state, action) => {
        state.error = action.error.message!;
        state.isLoading = false;
      });
  },
  reducers: {
    clearData(state: GoodWaterFromCenterSlicePropsSlce) {
      state.data = [];
      state.error = null;
      state.isLoading = false;

    },
  },
});

export const getAllDataGoodWaterFromCenter = (state: any) =>
  state.goodWaterFromCenterEnv;
export const GoodWaterFromCenterActions = GoodWaterFromCenterSlice.actions;
export default GoodWaterFromCenterSlice.reducer;
