import { createSlice } from "@reduxjs/toolkit";

const FloodControlv2Slice = createSlice({
  name: "FloodControlV2Envs",
  initialState: {
    openPasswordCheckBox: false,
    enableSwitch: false,
    message: "",
  },
  reducers: {
    clearState(state: any) {
      state.openPasswordCheckBox = false;
      state.enableSwitch = false;
      state.message = "";
    },
    turnTheSwitch(state: any, action) {
      console.log(action["payload"]);
    },

    setClosePasswordCheckBox(state: any) {
      state.openPasswordCheckBox = false;
    },
    setOpenPasswordCheckBox(state: any) {
      state.openPasswordCheckBox = true;
    },
    checkPassword(state: any, action) {
    

      if (
        action["payload"]["password"] === "ce6ee822-030c-430a-904b-8aada4204d8a"
      ) {
        state.enableSwitch = true;
        state.openPasswordCheckBox = false;
      } else {
        state.message = "กรอกรหัสผ่านผิด";
      }
    },
  },
});

export const FloodControlv2Actions = FloodControlv2Slice.actions;
export default FloodControlv2Slice.reducer;
