import { createSlice } from "@reduxjs/toolkit";


const initialState = {
  title: "",
  isShowPins: false,
  isShowDialogDetail:false,
  data: [
    {
      tag: "Pioneer Elastic (Thailand)",
      date: "22/4/65",
      location_url: "https://goo.gl/maps/oq1VYzZFxopUfLVj8",
      location_lat_long: "13.5486237637289, 100.22288310221742",
    },
    {
      tag: "ชุมแสงเท็กซ์ไทล์",
      date: "19/6/65",
      location_url: "https://goo.gl/maps/daUK3Y4LZDqDcJnx5",
      location_lat_long: "13.553566624883107, 100.22700297242132",
    },
    {
      tag: "จักรยานยนต์ถูกชนเสียชีวิต",
      date: "3/2/66",
      location_url: "https://goo.gl/maps/DWZQGPAYHquccxbf6",
      location_lat_long: "13.545863927786236, 100.23180183500163",
    },
    {
      tag: "รถยนต์ไล่ชนรถจักรยานยนต์เสียชีวิต",
      date: "19/1/66",
      location_url: "https://goo.gl/maps/aNAWD6Hr9HvNxcvn6",
      location_lat_long: "13.550966, 100.231096",
    },
  ],
}
const accidentPlanSlice = createSlice({
  name: "accidentPlanEnv",
  initialState: initialState,
  reducers: {
    setTitle(state: any, action) {
      state.title = action.payload["title"];
    },
    setShowPin(state: any) {
      state.isShowPins = !state.isShowPins;
    },
    setClosePin(state: any) {
      if (state.isShowPins) {
        state.isShowPins = false;
      }
    },
    setShowDialog(state: any) {
      state.isShowDialogDetail = !state.isShowDialogDetail;
    },
   
  },
});

export const accidetnPlanActions = accidentPlanSlice.actions;
export default accidentPlanSlice.reducer;
