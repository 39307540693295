import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import axios from "axios";
import { RequestItwinProp } from "interface/request_props";
import { URLLeftMenu } from "url/left_menu/url";

export const fetchWeather = createAsyncThunk("weatherApi", async () => {
  const res = await axios(URLLeftMenu.URL_WEATHER());
  const data = await res.data;
  return data;
});

export type DataWeatherPropsSlice = {
  data: DataWeatherProps;

  weatherStationElementId: string;
  isHadInit: boolean;
} & RequestItwinProp;
export type DataWeatherProps = {
  data_wind: DataWind;
  data: DataDetail;
  battery: string;
  weatherStationElementId: string;
};

interface DataWind {
  direction_angle: number[];
  direction_name: string[];
  direction_file: string[];
  direction_file_url: string;
}

export type DataDetail = {
  device_id: string;
  time_stamp: string;
  minrain: number;
  totalrain: number;
  state: number;
  solarrad: number;
  dailyrad: number;
  windspd: number;
  winddir: number;
  temp: number;
  humid: number;
  airpressure: number;
} & WeatherExtraDetail;
type WeatherExtraDetail = {
  url: string;
  windDirection: string;
  nameDirection: string;
};
const initialState: DataWeatherPropsSlice = {
  data: {
    data_wind: {
      direction_angle: [],
      direction_name: [],
      direction_file: [],
      direction_file_url: "",
    },
    data: {
      device_id: "",
      time_stamp: "",
      minrain: 0,
      totalrain: 0,
      state: 0,
      solarrad: 0,
      dailyrad: 0,
      windspd: 0,
      winddir: 0,
      temp: 0,
      humid: 0,
      airpressure: 0,
      url: "",
      windDirection: "",
      nameDirection: "",
    },
    battery: "",
    weatherStationElementId: "",
  },
  isLoading: true,
  error: "",
  isHadInit: false,
  weatherStationElementId: "0x20000001db0",
};
const WeatherSlice = createSlice({
  name: "weatherEnv",
  initialState: initialState,
  extraReducers: (builder) => {
    builder
      .addCase(fetchWeather.pending, (state) => {
        if (!state.isHadInit) {
          state.isLoading = true;
        }
      })
      .addCase(fetchWeather.fulfilled, (state, action) => {
        const data_wind = action.payload.data_wind;
        const status_data = action.payload.data;

        const direction_name = data_wind.direction_name;
        const direction_file = data_wind.direction_file;
        const direction_file_url = data_wind.direction_file_url;
        const windDirection = status_data.winddir;
        var nameDirection = "";
        var url;
        if (windDirection >= 0 && windDirection < 45) {
          url = direction_file_url + direction_file[0];
          nameDirection = direction_name[0];
        } else if (windDirection >= 45 && windDirection < 90) {
          url = direction_file_url + direction_file[1];
          nameDirection = direction_name[1];
        } else if (windDirection >= 90 && windDirection < 135) {
          url = direction_file_url + direction_file[2];
          nameDirection = direction_name[2];
        } else if (windDirection >= 135 && windDirection < 180) {
          url = direction_file_url + direction_file[3];
          nameDirection = direction_name[3];
        } else if (windDirection >= 180 && windDirection < 225) {
          url = direction_file_url + direction_file[4];
          nameDirection = direction_name[4];
        } else if (windDirection >= 225 && windDirection < 270) {
          url = direction_file_url + direction_file[5];
          nameDirection = direction_name[5];
        } else if (windDirection >= 270 && windDirection < 315) {
          url = direction_file_url + direction_file[6];
          nameDirection = direction_name[6];
        } else if (windDirection >= 315 && windDirection < 360) {
          url = direction_file_url + direction_file[7];
          nameDirection = direction_name[7];
        }
        state.data.data = {
          nameDirection: nameDirection,
          url: url,
          windDirection: windDirection,
          temp: status_data.temp,
          windspd: status_data.windspd,
          humid: status_data.humid,
          airpressure: status_data.airpressure,

          device_id: "",
          time_stamp: "",
          minrain: 0,
          totalrain: 0,
          state: 0,
          solarrad: 0,
          dailyrad: 0,
          winddir: 0,
        };
        state.isHadInit = true;
        state.isLoading = false;
      })

      .addCase(fetchWeather.rejected, (state, action) => {
        state.error = action.error.message!;
        state.isLoading = false;
      });
  },
  reducers: {
    setLoading(state: any, action) {
      state.isLoading = action.payload;
    },
    clearData(state: any) {
      state.data = {};
      state.error = "";
      state.isHadInit = false;

      state.isLoading = false;
    },
  },
});

export const getAllDataWeather = (state: any) => state.weatherEnv;
export const WeatherActions = WeatherSlice.actions;
export default WeatherSlice.reducer;
