import { createSlice } from "@reduxjs/toolkit";
import { BASEURL } from "url/url_main";
export const getFactoryLink = (projectToken: string) =>
  `/viewer-factory?project_token=${projectToken}`;
export type factorySliceProps = {
  id:number
  factory_name:string
  factory_name_eng:string
  factory_new_licence_id:string
  factory_industy:string
}
  const mainFactoryDashBoard = {
    factoryData: [
      {
        id: 1,
        factory_name: "เอเพ็กซ์ เซอร์คิต (ไทยแลนด์)",
        factory_name_eng: "APEX CIRCUIT (THAILAND) CO.,LTD.",
        factory_new_licence_id: "72100001425447",
        factory_industy: "ผลิตแผงวงจรพิมพ์",
        model_id: "0x2000000137b",
        video:
          `${BASEURL}/media/video_itwin_factory/APEX%20CIRCUIT%20%28THAILAND%29.mp4`,
        video_old: "https://www.youtube.com/embed/HHCMIsnmTbg",
        // link_3D: URL_3D_LINK("1968d137d20f45b98b18fda55dfe7793"),
        auto_desk:"https://autode.sk/3vj39DA",  
        link_3D: getFactoryLink("1968d137d20f45b98b18fda55dfe7793"),
      },
      {
        id: 2,
        factory_name: "วี อาร์ ฟู้ดส์",
        factory_name_eng: "VR FOODS COMPANY LIMITED",
        factory_new_licence_id: "72100000125394",
        factory_industy: "ผลิตผัก ผลไม้กระป๋อง ปลา และผลไม้แช่แข็ง",
        model_id: "0x20000002883",
        video: `${BASEURL}/media/video_itwin_factory/vr_food.mp4`,
        video_old: "https://youtube.com/embed/84au1FsrTB0",
        // link_3D: URL_3D_LINK("d3b2f4fe0c1747f9b056e5245e5f68f0"),
        auto_desk:"https://autode.sk/47lVEJj",


        link_3D: getFactoryLink("d3b2f4fe0c1747f9b056e5245e5f68f0"),
      },
      {
        id: 3,
        factory_name: "ยูบิส (เอเชีย)",
        factory_name_eng: "UBIS (ASIA) PUBLIC COMPANY LIMITED",
        factory_new_licence_id: "72100300125383",
        factory_industy: "ผลิตด้ายยาง ยางเส้น ยางแผ่น และใช้เป็นคลังสินค้า",
        model_id: "0x2000000288c",
        video: `${BASEURL}/media/video_itwin_factory/Ubis.mp4`,
  
        video_origin: "https://youtube.com/embed/y-fZPd8Vi6c",
        auto_desk:"https://autode.sk/3S1sSJ",

        link_3D: getFactoryLink("981d05083798456c88ab55dfefb8142d"),
      },
  
      {
        id: 4,
        factory_name: "ฮูทามากิ (ประเทศไทย)",
        factory_name_eng: "HUHTAMAKI (THAILAND) LIMITED",
        factory_new_licence_id: "82100100125490,72100000425406",
        factory_industy: "ผลิตบรรจุภัณฑ์ทำจากพลาสติกและกระดาษ",
        model_id: "0x20000000f45",
        video: `${BASEURL}/media/video_itwin_factory/HUHTAMAKI.mp4`,
        video_origin: "https://youtube.com/embed/lACA4dmZKqA",
        // link_3D: URL_3D_LINK("47d280f0871e485aa15663f41bdb4b69"),
        auto_desk:"https://autode.sk/41J8EY5",

        link_3D: getFactoryLink("47d280f0871e485aa15663f41bdb4b69"),
      },
  
      {
        id: 5,
        factory_name: "ลองเท็กซ์รับเบอร์อินดัสตรี",
        factory_name_eng: "LONG TEX RUBBER INDUSTRY COMPANY LIMITED",
        factory_new_licence_id: "72100300125383",
        factory_industy: "ผลิตด้ายยาง ยางเส้น ยางแผ่น และใช้เป็นคลังสินค้า",
        model_id: "0x20000002889",
        video: "https://bim-ams.com/media/video_itwin_factory/long_tex.mp4",
        video_origin: "https://youtube.com/embed/BX60bMZ7-z8",
        // link_3D: URL_3D_LINK("a068fa33d9da4aba8c59913177e41b31"),
        auto_desk:"https://autode.sk/3RHVC8F",


        link_3D: getFactoryLink("a068fa33d9da4aba8c59913177e41b31"),
      },
    ],
    elementIdsThatClearColor:["0x2000000187a","0x2000000261e"],
  }
const initialState = {
  factory_name: "",
  factory_name_eng: "",
  factory_new_licence_id: "",
  factory_industy: "",
  isOpenDangerPlan: false,
  ...mainFactoryDashBoard,
 
};
const factorySlice = createSlice({
  name: "factorynEnv",
  initialState: initialState,
  reducers: {
    clearDetail(state: any) {
      state.factory_name = "";
      state.factory_name_eng = "";
      state.factory_new_licence_id = "";
      state.factory_industy = "";
    },
    setDetail(state: any, action) {
      state.factory_name = action.payload["factory_name"];
      state.factory_name_eng = action.payload["factory_name_eng"];
      state.factory_new_licence_id = action.payload["factory_new_licence_id"];
      state.factory_industy = action.payload["factory_industy"];
    },
    toggleOpenDangerPlan(state: any) {
      state.isOpenDangerPlan = !state.isOpenDangerPlan;
    },
    closeOpenDangerPlan(state: any) {
      state.isOpenDangerPlan = false;
    },
  },
});

export const getAllFactoryData = (state:any)=>state.factorynEnv
export const factoryActions = factorySlice.actions;
export default factorySlice.reducer;
