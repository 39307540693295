import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import axios from "axios";
import { IEAT_MachineStatusDialogTag } from "enum";
import { RequestItwinProp } from "interface/request_props";
import { object, objectOf } from "prop-types";
import { URLGeneral } from "url/url_main";
export const fetchBadWaterFromCenter = createAsyncThunk(
  "badwaterformcenterapi",
  async () => {
    const res = await axios(
      URLGeneral.URL_MACHINE_STATUS(IEAT_MachineStatusDialogTag.FLOW_AND_DO)
    );
    const data = await res.data;

    return data;
  }
);

export type BadWaterFromCenterProps ={
  flow_meter: FlowMeter[];
  DO?: Do|undefined|null;
  motor: Motor[];
}

export  type FlowMeter ={
  device_id: string;
  flow_rate: number;
  volume: number;
  flow_enable: string;
}

export type Do= {
  device_id: string;
  battery: string;
  saturation: number;
  oxygen: number;
  watertemp: number;
}

type Motor ={
  device_id: string;
  status: string;
}

export type BadWaterFromCenterSliceProps = {
  data: BadWaterFromCenterProps;
  isHaveData: boolean;
} & RequestItwinProp;

const initialState: BadWaterFromCenterSliceProps = {
  data:{
    flow_meter: [],
    motor: []
  },
  isHaveData: false,
  isLoading:true,
  error:null
};
const BadWaterFromCenterSlice = createSlice({
  name: "badWaterFromCenterEnv",
  initialState: initialState,
  extraReducers: (builder) => {
    builder
      .addCase(fetchBadWaterFromCenter.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchBadWaterFromCenter.fulfilled, (state, action) => {
        state.data = {
          DO: action.payload.DO,
          flow_meter:action.payload.flow_meter,
          motor:action.payload.motor,
          // flowRate40C7B8: action.payload.flow_meter.find(
          //   (item: any) => item.device_id === "40C7B8"
          // ),
          // flowRate40C9E8: action.payload.flow_meter.find(
          //   (item: any) => item.device_id === "40C9E8"
          // ),
        };
        state.isHaveData = true;
        state.isLoading = false;
      })
      .addCase(fetchBadWaterFromCenter.rejected, (state, action) => {
        state.error = action.error.message!;
        state.isLoading = false;
      });
  },
  reducers: {
    clearData(state: any) {
      console.log("clear");

      state.data = {};
      state.isLoading = false;
      state.error = "";
    },
  },
});

export const getAllDataBadWaterFromCenter = (state: any) =>
  state.badWaterFromCenterEnv;
export const BadWaterFromCenterAction = BadWaterFromCenterSlice.actions;
export default BadWaterFromCenterSlice.reducer;
