import { createSlice } from "@reduxjs/toolkit";

const FloodControlSlice = createSlice({
  name: "FloodControlEnvs",
  initialState: {
    openPasswordCheckBox: false,
    enableSwitch: false,
    message: "",
  },
  reducers: {
    clearState(state: any) {
      state.openPasswordCheckBox = false;
      state.enableSwitch = false;
      state.message = "";
    },
    turnTheSwitch(state: any, action) {
      console.log(action["payload"]);
    },

    setClosePasswordCheckBox(state: any) {
      state.openPasswordCheckBox = false;
    },
    setOpenPasswordCheckBox(state: any) {
      state.openPasswordCheckBox = true;
    },
    checkPassword(state: any, action) {
      if (
        action["payload"]["password"] === "3714dd8e-dbac-4a1d-8024-4e086c956d14"
      ) {
        state.enableSwitch = true;
        state.openPasswordCheckBox = false;
      } else {
        state.message = "กรอกรหัสผ่านผิด";
      }
    },
  },
});

export const FloodControlActions = FloodControlSlice.actions;
export default FloodControlSlice.reducer;
