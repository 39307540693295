import { createSlice } from "@reduxjs/toolkit";

// Define a type for the slice state
interface MenuControlState {
  title: string;
}

// Define the initial state using that type
const initialState: MenuControlState = {
  title: "",
};

export const MenuControlSliceSlice = createSlice({
  name: "menuControll",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    showMenu: (state, actions) => {
      if (actions.payload === state.title) {
        state.title = "";
      } else {
        state.title = actions.payload;
      }
    },
    closeMenu: (state,actions) => {
      state.title = "";
    },
  },
});

export const MenuControlActions = MenuControlSliceSlice.actions;

export default MenuControlSliceSlice.reducer;
