import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { URLLeftMenu } from "url/left_menu/url";



const initialState ={
    data: [],
    dateTemp: [],
  isLoading: false,
  error: "",
  
}

export const fetchAssetTable = createAsyncThunk(
    "asssetTableApi",
    async () => {
      const res = await axios(URLLeftMenu. URL_ASSET_TABLE());
      const data = await res.data
      return data;
    }
  );
const assetTableSlice = createSlice({
  name: "assetTableEnv",
  initialState: initialState,
  extraReducers: (builder) => {
    builder.addCase(fetchAssetTable.pending, (state) => {
      state.isLoading = true;
    }).addCase(fetchAssetTable.fulfilled, (state, action) => {
        state.isLoading = false
        state.data = action.payload


        state.dateTemp = action.payload

        
      }).addCase(fetchAssetTable.rejected, (state, action) => {
        state.isLoading = false
        state.error = action.error.message!
      })
  },
  reducers: {
   
  },
});

export const assetTableActions = assetTableSlice.actions;
export default assetTableSlice.reducer;
