import { IEAT_MachineStatusDialogTag } from "enum";
import getTokenFromUrlParameter from "hook/useGetTokenHoook";

export const BASEURL = "https://bim-ams.com";
// export const BASEURL = "http://localhost:8000"
export const THINGSONNET_URL = "https://api.thingsonnet.net"

export class URLGeneral {
  static URL_ASSET_PROFILE = (
    asset_profile_type?: "น้ำเสียส่วนกลาง"
  ):string =>{
   const token =   getTokenFromUrlParameter({})
   return `${BASEURL}/IEAT/api/v1/asset-profile/?token=${token}&&asset_profile_type=${asset_profile_type}`;

  }

  static URL_MACHINE_STATUS = (tag: IEAT_MachineStatusDialogTag) =>
    `${BASEURL}/IEAT/api/v1/machine/status/${tag}/`;

  static URL_MAPPING_DATA = (tag: string, project_token: string | null) =>
    `${BASEURL}/IEAT/api/v1/asset/mapping/${tag}/?project_token=${project_token}`;

  static URL_MAPPING_DATA_ITWIN = (
    tag: "lighting" | "cctv",
    project_token: string | null
  ) =>
    `${BASEURL}/IEAT/api/v2/asset/mapping/${tag}/?project_token=${project_token}`;

    static URL_PROJECT_ITWIN_DETIAL = (token: string) =>
    `${BASEURL}/api/v1/project/iTwin/${token}`;

//on factory
  static URL_3D_LINK = (token: string) =>
  `${BASEURL}/IEAT/factory/viewer?project_token=${token}`;

}
