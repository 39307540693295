import { IModelApp, StandardViewId } from "@itwin/core-frontend";
import { createSlice } from "@reduxjs/toolkit";
import {
  ElementOfInterest,
  ElementOfInterestDecorator,
} from "components/ElementOfInterest/ElementOfInterestDecorator";
interface elementofInterestProps {
  elements: ElementOfInterest[];
  elementOfInterestdecorator?: any;
}
const initialState: elementofInterestProps = {
  elements: [],
  elementOfInterestdecorator: null,
};

const elementOfInterestSlice = createSlice({
  name: "elementOfInterestEnv",
  initialState: initialState,
  reducers: {
    addElement(state: any, action) {
      //   state.elements. push({id: "0x20000001405", title: "Motor Demo", viewOrientation: StandardViewId.Front},)
      state.elements.push(action.payload);
    },
    setElementOfInterestDecorator(state: any, action) {
      state.elementOfInterestdecorator = action.payload
    },
    clearElement(state: any) {

      console.log("clear element");
      IModelApp.viewManager.dropDecorator(state.elementOfInterestdecorator);
      state.elements = [];
      state.elements.length = 0;

    },
  },
});

export const elementOfInterestSliceActions = elementOfInterestSlice.actions;
export default elementOfInterestSlice.reducer;
